import { callGqlApi } from "../../helpers/api";
import {
  DEVICES_SET_CODESPACES,
  DEVICES_SET_CODESPACES_ERROR,
  DEVICES_SET_MODELS,
  DEVICES_SET_LIST,
  DEVICES_SET_ERROR,
  DEVICES_SET_PERSONAL,
  DEVICES_SET_PERSONAL_LOADING,
  DEVICES_SET_PERSONAL_ERROR,
  DEVICES_UPDATE_ONE,
  DEVICE_DELETE_EVENTS,
} from "../action-types";
import { customSnackbar, basicSnackbar } from "../snackbar/snackbar-actions";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { parseDateTime } from "../../helpers/time";
import { addToStudy } from "../study/study-actions";
import gql from "../gqlTag";

// DATA LIST
export function readDeviceList() {
  return async dispatch => {
    const devicesQuery = gql`
      query listDevices {
        devices {
          id
          serial
          salesOrder
          shipDate
          state
          model
          stockCode
          sensorsSupport0ADC
          capabilities
          transmitters {
            displayId
            transmissionType
            ... on CodedTransmitter {
              sensorDef {
                slope
                intercept
                dimension
                displayString
              }
              codespaceDisplayString
              codingId
              transmitId
            }
            ... on HtiTransmitter {
              htiPeriodMs
              htiSubcode
              htiPulseWidth
              htiModulation
            }
          }
          rxLogFiles
          conflicts {
            __typename
            conflictingDeviceIds
            ... on DeviceConflictSerial {
              serial
            }
            ... on DeviceConflictDisplayId {
              displayId
            }
          }
          studyConflicts {
            studyId
            conflict {
              __typename
              conflictingDeviceIds
              ... on DeviceConflictSerial {
                serial
              }
              ... on DeviceConflictDisplayId {
                displayId
              }
            }
          }
          events {
            id
            date
            notes
            __typename
            ... on DeviceEventOffload {
              fileId
            }
            ... on DeviceEventTagging {
              animal {
                id
                name
              }
            }
            ... on DeviceEventRecovery {
              animal {
                id
                name
              }
            }
            ... on DeviceEventRetrieval {
              latLon {
                latitude
                longitude
              }
              deployment {
                id
                station {
                  name
                }
              }
            }
            ... on DeviceEventDeployment {
              latLon {
                latitude
                longitude
              }
              deployment {
                id
                station {
                  name
                }
              }
            }
          }
          deviceClasses
          source
          retired
          transmitterDelaySequence {
            delayType
            preciseDelay
            jump
            isNormal
            steps {
              number
              state
              duration
              power
              ppmCodespace
              ppmMin
              ppmMax
              sampleWindow
              hrCodespace
              hrMin
              hrMax
              htiConfigOption
              htiPingRatio
            }
          }
        }
      }
    `;

    const modelQuery = gql`
      query getReceiverModels {
        receiverModels: models(classes: RECEIVER, harmonized: true)
        tagModels: models(classes: TAG, harmonized: true)
      }
    `;
    callGqlApi(devicesQuery)
      .then(response => {
        const devices = segregateReservedTransmitters(response.devices);
        dispatch({
          type: DEVICES_SET_LIST,
          payload: {
            devices: devices,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });

    callGqlApi(modelQuery)
      .then(response => {
        dispatch({
          type: DEVICES_SET_MODELS,
          payload: {
            models: response,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function getCodespaces() {
  return async dispatch => {
    const codespacesQuery = gql`
      query {
        codespaces {
          displayString
        }
      }
    `;

    callGqlApi(codespacesQuery)
      .then(response => {
        dispatch({
          type: DEVICES_SET_CODESPACES,
          payload: {
            codespaces: response.codespaces,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_CODESPACES_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function getPersonalDevices() {
  return async (dispatch, getState) => {
    // Get the user's personal workspace id:
    const workspaceId = getState().workspaces.workspaces?.filter(w => w.isPersonal)[0]?.id;
    const devicesQuery = gql`
      query getPersonalDevices($workspaceId: ID!) {
        devices(workspaceId: $workspaceId) {
          id
          serial
          salesOrder
          shipDate
          state
          model
          stockCode
          capabilities
          deviceClasses
          source
          transmitters {
            displayId
            ... on CodedTransmitter {
              transmitId
            }
          }
        }
      }
    `;

    dispatch({
      type: DEVICES_SET_PERSONAL_LOADING,
    });

    callGqlApi(devicesQuery, { workspaceId })
      .then(response => {
        const devices = segregateReservedTransmitters(response.devices);
        dispatch({
          type: DEVICES_SET_PERSONAL,
          payload: {
            devices: devices,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_PERSONAL_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export async function lookupSerial(serial) {
  const lookupDeviceQuery = gql`
    query lookupDevice($serial: String!) {
      lookupDevice(serial: $serial) {
        model
        deviceClasses
      }
    }
  `;

  try {
    const device = await callGqlApi(lookupDeviceQuery, { serial });
    return device.lookupDevice;
  } catch (e) {
    return undefined;
  }
}

export function addDeviceManually(device, studyId) {
  return async dispatch => {
    const createDeviceQuery = gql`
      mutation createDevice($input: CreateDeviceInput!) {
        createDevice(input: $input) {
          device {
            id
          }
        }
      }
    `;

    return callGqlApi(createDeviceQuery, { input: device })
      .then(response => {
        if (studyId) {
          return dispatch(addToStudy({ studyId, deviceIds: [response.createDevice.device.id] }));
        }
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function addDevicesBySerial(workspaceId, serials) {
  return async (dispatch, getState) => {
    const addDevicesQuery = gql`
      mutation addDeviceBySerial($input: AddSysproDevicesInput!) {
        addSysproDevices(input: $input) {
          deviceIds
        }
      }
    `;

    return callGqlApi(addDevicesQuery, { input: { workspaceId, serials } })
      .then(response => {
        const { deviceIds } = response.addSysproDevices;
        const destWorkspace = getState().workspaces.workspaces?.find(w => w.id == workspaceId);
        const selectedWorkspace = getState().workspaces.selectedWorkspace;

        if (deviceIds.length) {
          if (destWorkspace.id == selectedWorkspace.id) {
            // only refresh device list if devices were added to current workspace
            dispatch(readDeviceList());
          }
          const studyId = getState().study.selectedId;
          if (studyId) {
            dispatch(addToStudy({ studyId, deviceIds }));
          }
          dispatch(
            basicSnackbar(
              `successfully added ${deviceIds.length} device(s) to workspace: ${destWorkspace.name}`,
              "success"
            )
          );
        }

        if (deviceIds.length < serials.length) {
          // TODO: get device serials for returned ids and compare with requested serials to alert
          //       the user if any serials didn't work (also check if failed bc device already exist)
          const numFailed = serials.length - deviceIds.length;
          dispatch(
            basicSnackbar(
              `${numFailed} device(s) weren't added. They may already exist in ${destWorkspace.name} or the serial(s) are invalid`,
              "warning"
            )
          );
        }
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function editDeviceManually(device) {
  return async dispatch => {
    const updateDeviceInput = { ...device };
    updateDeviceInput.deviceId = device.id;
    delete updateDeviceInput.source; // can't update source
    delete updateDeviceInput.id; // this is dumb; but is quick fix. Proper fix needs GraphQL update as well (use id instead of deviceId)
    const updateDeviceQuery = gql`
      mutation updateDevice($input: UpdateDeviceInput!) {
        updateDevice(input: $input) {
          device {
            model
            serial
            deviceClasses
            sensorsSupport0ADC
            transmitters {
              displayId
              ... on CodedTransmitter {
                transmitId
                sensorDef {
                  dimension
                  slope
                  intercept
                }
              }
            }
          }
        }
      }
    `;

    return callGqlApi(updateDeviceQuery, { input: updateDeviceInput })
      .then(response => {
        dispatch({
          type: DEVICES_UPDATE_ONE,
          payload: {
            deviceId: device.id,
            device: response.updateDevice.device,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deleteDevices(devices) {
  return async dispatch => {
    const deleteDeviceQuery = gql`
      mutation deleteDevice($input: DeleteDeviceInput!) {
        deleteDevice(input: $input) {
          deletedId
        }
      }
    `;

    const promises = devices.map(device => {
      const updateDeviceInput = { deviceId: device.id };
      return callGqlApi(deleteDeviceQuery, { input: updateDeviceInput });
    });

    Promise.all(promises)
      .then(() => {
        dispatch(readDeviceList());
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function rebatteryDevices(event) {
  return async dispatch => {
    const deviceEventInput = {
      deviceIds: event.deviceIds,
      date: parseDateTime(event.date).toISOString(),
      notes: event.notes,
    };

    const rebatteryEventQuery = gql`
      mutation rebatteryEvent($input: RebatteryEventInput!) {
        rebatteryEvent(input: $input) {
          eventIds
        }
      }
    `;

    callGqlApi(rebatteryEventQuery, { input: deviceEventInput })
      .then(() => {
        dispatch(readDeviceList());
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function retireDevices(deviceIds, clearSelection) {
  return async dispatch => {
    const retireDevices = gql`
      mutation retireDevices($input: RetiredDevicesInput!) {
        retireDevices(input: $input) {
          deviceIds
        }
      }
    `;

    callGqlApi(retireDevices, {
      input: { deviceIds: deviceIds },
    })
      .then(response => {
        clearSelection();
        dispatch(readDeviceList());
        const successKey = "device-retire-success";
        dispatch(
          customSnackbar(
            "device-retire-success",
            { deviceIds: response.retireDevices.deviceIds },
            successKey
          )
        );
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function unretireDevices(deviceIds) {
  return async dispatch => {
    const unretireDevices = gql`
      mutation unretireDevices($input: RetiredDevicesInput!) {
        unretireDevices(input: $input) {
          deviceIds
        }
      }
    `;
    callGqlApi(unretireDevices, {
      input: { deviceIds: deviceIds },
    })
      .then(() => {
        dispatch(readDeviceList());
        dispatch(basicSnackbar("Devices unretired", "success"));
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deleteDeviceEvents(eventIds, deviceId) {
  return async dispatch => {
    const deleteDeviceEvents = gql`
      mutation deleteDeviceEvents($input: DeleteDeviceEventsInput!) {
        deleteDeviceEvents(input: $input) {
          success
        }
      }
    `;

    callGqlApi(deleteDeviceEvents, { input: { eventIds: eventIds } })
      .then(() => {
        dispatch({
          type: DEVICE_DELETE_EVENTS,
          payload: {
            deviceId: deviceId,
            deleteEventIds: eventIds,
          },
        });
        dispatch(readDeviceList());
        dispatch(basicSnackbar("Successfully deleted the device event", "success"));
      })
      .catch(errors => {
        dispatch({
          type: DEVICES_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function copyDevicesToWorkspace(deviceIds, targetWorkspaceId) {
  return async (dispatch, getState) => {
    const copyDevicesMut = gql`
      mutation copyDevicesToWorkspace($input: CopyDevicesToWorkspaceInput!) {
        copyDevicesToWorkspace(input: $input) {
          newDevices {
            id
          }
        }
      }
    `;

    await callGqlApi(copyDevicesMut, { input: { deviceIds, targetWorkspaceId } })
      .then(async response => {
        const { newDevices } = response.copyDevicesToWorkspace;
        // Also link devices to study if one is selected:
        const studyId = getState().study.selectedId;
        if (studyId) {
          const deviceIds = newDevices.map(d => d.id);
          await dispatch(addToStudy({ studyId, deviceIds }));
        }
        const n = newDevices.length;
        const wsName = getState().workspaces.workspaces.find(w => w.id === targetWorkspaceId).name;
        const message = `${n} device${n > 1 ? "s" : ""} copied to workspace: ${wsName}.`;
        dispatch(basicSnackbar(message, "success"));
        dispatch(readDeviceList());
      })
      .catch(errors => {
        dispatch(handleGqlErrors(errors));
      });
  };
}

function segregateReservedTransmitters(devices) {
  // Some devices have multiple transmitters, some of which are "reserved" for future use
  // Segregate reserved transmitters so they can be handled separately (ex: hide from users)
  const reservedTransmitterIDStockCodes = ["NEXTRAK-R1"];

  const alteredDevices = devices.map(d => {
    const isReservedTransmitterIDModel = reservedTransmitterIDStockCodes.some(
      sc => sc === d.stockCode
    );

    if (isReservedTransmitterIDModel) {
      d.transmitters.sort((tx1, tx2) => tx1.transmitId - tx2.transmitId);
      const displayTransmitters = d.transmitters.slice(0, 1);
      const reservedTransmitters = d.transmitters.slice(1);
      d.transmitters = displayTransmitters;
      d.reservedTransmitters = reservedTransmitters;
    }

    return d;
  });

  return alteredDevices;
}
